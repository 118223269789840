import React, { useEffect, useState } from 'react';
import PositionedModal from '../../../_ens/layout/components/modal/modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { weekDays, isAdmin } from '../../servics/CommonFunctions';
import DeleteModal from '../../../_ens/layout/components/Confirmation';
import {
  Availbility,
  modifyavailbility,
  deleteAvailbilities
} from '../../modules/Admin/AdminActions';

function SchedulePopup(props) {
  const { intl } = props;

  const [modalShow, setmodalShow] = useState(() => false);

  const [initialValues, setIntialValues] = useState(() => props.scheduleobj);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [radiobtn, setradiobtn] = useState(0);
  const [interpratorList] = useState(props.scheduleobj.interpratorList);

  useEffect(() => {
    setmodalShow(props.modalShow);
    if (props.scheduleobj.id) {
      setIntialValues({ ...initialValues, reoccurring: 0 });
    }

    return () => {
      setmodalShow(false);
      formik.resetForm(() => {
        console.log('reset done');
      });
    };
  }, [props]);

  const setRadiobtn = (val) => {
    setradiobtn(val);
  };

  const AppointmentSchema = Yup.object().shape({
    date: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    time: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    endTime: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /*if (formik.touched[fieldname] && !formik.errors[fieldname]) {
          return "is-valid";
        }*/

    return '';
  };

  const onModelHide = () => {
    setmodalShow(false);
    props.onModelHide();
  };

  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };

  const handleCheckbox = (e) => {
    let value = { ...initialValues };
    value[e.target.name] = e.target.checked;
    setIntialValues(value);
  };

  const handleAllCheckbox = (e) => {
    let currentValues = { ...initialValues };
    currentValues[e.target.name] = e.target.checked;
    for (let i = 1; i < 6; i++) {
      currentValues['day' + i] = e.target.checked;
    }
    setIntialValues(currentValues);
    //formik.setValues({...formik.values,currentValues})
  };

  const deleteAvailbility = () => {
    let datatosend = {
      id: props.scheduleobj.id,
      reoccurring: radiobtn
    };

    deleteAvailbilities(datatosend).then((res) => {
      onModelHide();
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      if (props.scheduleobj.id) {
        // change by developer
        let datatosend = {
          id: values.id,
          // time: tConvert(values.time),
          // endTime: tConvert(values.endTime),
          time: values.time,
          endTime: values.endTime,
          date: values.date,
          reoccurring: radiobtn
        };
        console.log({ datatosend });
        modifyavailbility(datatosend)
          .then((res) => {
            props.onModelHide();
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        let dataToSend = {};
        let tempArray = [];
        for (let i = 1; i < 6; i++) {
          if (initialValues['day' + i]) {
            tempArray.push(i);
          }
        }
        dataToSend.interpreterID = values.interpreterID;
        dataToSend.time = values.time;
        dataToSend.endTime = values.endTime;
        dataToSend.date = values.date;

        if (values.dateUntil != '') dataToSend.dateUntil = values.dateUntil;
        if (tempArray.toString() != '') dataToSend.repeatDays = tempArray.toString();
        Availbility(dataToSend)
          .then(
            (val) => {
              props.onModelHide();
            },
            (err) => {}
          )
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  });

  return (
    <div>
      <DeleteModal
        open={confirmDelete}
        title={intl.formatMessage({
          id: 'CONFIRMATION'
        })}
        msg={intl.formatMessage({
          id: 'APPOINTMENT_DELETE'
        })}
        handleCancel={() => setConfirmDelete(false)}
        cacelColor="btn btn-light btn-elevate"
        cancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        handleOk={deleteAvailbility}
        okColor="btn btn-delete btn-elevate"
        okText={intl.formatMessage({
          id: 'DELETE'
        })}
      ></DeleteModal>

      <PositionedModal
        center={true}
        show={modalShow}
        title={intl.formatMessage({
          id: 'DASHBOARD.SCHEDULR'
        })}
        onHide={onModelHide}
        onSubmit={formik.handleSubmit}
        updateText={intl.formatMessage({
          id: 'SAVE'
        })}
        CancelText={intl.formatMessage({
          id: 'CANCEL'
        })}
        deletebtn={() => setConfirmDelete(true)}
        deletetext={
          props.scheduleobj.id
            ? intl.formatMessage({
                id: 'DELETE'
              })
            : ''
        }
        deleteClass="btn btn-delete btn-elevate"
      >
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group row">
            {/* First Name */}
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_DATE" />
              </label>
              <input
                placeholder="Start Date"
                type="date"
                readOnly={props.scheduleobj.id && !isAdmin()}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'date'
                )}`}
                name="date"
                {...formik.getFieldProps('date')}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.date}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-6">
              {!props.scheduleobj.id ? (
                <div>
                  <label className="col-form-label">
                    <FormattedMessage id="DASHBOARD.END_DATE" />
                  </label>
                  <input
                    placeholder="End Date"
                    type="date"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      'dateUntil'
                    )}`}
                    name="dateUntil"
                    {...formik.getFieldProps('dateUntil')}
                  />
                  {formik.touched.dateUntil && formik.errors.dateUntil ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.dateUntil}</div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.START_TIME" />
              </label>
              <input
                placeholder="Start Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'time'
                )}`}
                name="time"
                {...formik.getFieldProps('time')}
              />
              {formik.touched.time && formik.errors.time ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.time}</div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-6">
              <label className="col-form-label">
                <FormattedMessage id="DASHBOARD.END_TIME" />
              </label>
              <input
                placeholder="End Time"
                type="time"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'endTime'
                )}`}
                name="endTime"
                {...formik.getFieldProps('endTime')}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </div>
          </div>

          {isAdmin() && props.scheduleobj.interpreterID === '' ? (
            <div className="form-group row">
              <div className="col-lg-6">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.INTERPRATOR" />
                </label>

                <select
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'interpreterID'
                  )}`}
                  name="interpreterID"
                  {...formik.getFieldProps('interpreterID')}
                >
                  <option key="" disabled value="">
                    {intl.formatMessage({
                      id: 'DASHBOARD_SELECT_INTERPRATOR'
                    })}
                  </option>

                  {interpratorList &&
                    interpratorList.length > 0 &&
                    interpratorList.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          ) : null}

          {!props.scheduleobj.id ? (
            <div>
              <div className="form-group row mb-4">
                <label className="col-lg-12">
                  {' '}
                  <FormattedMessage id="DASHBOARD.SELECT_DAYS" />
                </label>
              </div>

              <div className="form-group row mb-4">
                <div className="col-lg-12 d-flex">
                  <label className="checkbox checkbox-lg checkbox-single mr-3">
                    <input
                      type="checkbox"
                      checked={initialValues['allchecked']}
                      name="allchecked"
                      onChange={handleAllCheckbox}
                    />
                    <FormattedMessage id="AVAILBILTY_ALL_DAYS" />
                    <span className="ml-2" />
                  </label>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-2 d-flex">
                  {weekDays.map((day) => {
                    return (
                      <label key={day.value} className="checkbox checkbox-lg checkbox-single mr-3">
                        <input
                          type="checkbox"
                          checked={initialValues['day' + day.value]}
                          name={'day' + day.value}
                          onChange={handleCheckbox}
                        />
                        <FormattedMessage id={day.name} />

                        <span className="ml-2" />
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          {props.scheduleobj.id ? (
            <div className="form-group row mb-4">
              <div className="col-lg-6">
                <input
                  type="radio"
                  checked={radiobtn === 0}
                  onChange={() => setRadiobtn(0)}
                  id="current"
                  name="reoccurring"
                  value={0}
                />
                <label htmlFor="current" className="ml-4">
                  {' '}
                  <FormattedMessage id="DASHBOARD.SCHEDULE_CURRENT_DAY" />
                </label>
              </div>
              <div className="col-lg-6">
                <input
                  type="radio"
                  checked={radiobtn === 1}
                  onChange={() => setRadiobtn(1)}
                  id="all"
                  name="reoccurring"
                  value={1}
                />
                <label htmlFor="all" className="ml-4">
                  {' '}
                  <FormattedMessage id="DASHBOARD.SCHEDULE_FURTER_DAYS" />
                </label>
              </div>
            </div>
          ) : null}
        </form>
      </PositionedModal>
    </div>
  );
}
export default injectIntl(SchedulePopup);
