import { Repository } from '../../servics/httpsServics';

const controller = '/chat';

export function getRooms(data) {
  return Repository.get(controller + '/get-all-rooms', {
    params: data
  });
}

export const getRoomChatMessages = (id) => {
  return Repository.get(controller + '/get-chat-messages', { params: { room_id: id } });
};
