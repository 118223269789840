import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as common from '../../../../redux/commonReducer';
import * as auth from '../_redux/authRedux';
import { login, resendOtp, setUserWithoutOTPVerification } from '../_redux/authCrud';

// for firebase notification device token set
// import { getToken } from '../../../../firebase_token';

const initialValues = {
  email: '',
  password: ''
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.WRONGEMAIL'
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /* if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      login(values.email, values.password)
        .then(async (res) => {
          if (res.otp_mode) {
            resendOtp(values.email).then((data) => {
              disableLoading();
              props.commonActions.showSnackBar(
                true,
                intl.formatMessage({
                  id: 'EMAIL_FOR_TOKEN'
                })
              );
              props.history.push({
                pathname: '/auth/verify-otp',
                search: '?email=' + values['email'] + '&token=' + res.token
              });
            });
          } else {
            if (res.reset_password) {
              return props.history.push('set-password?u=' + btoa(res.id));
            }
            // const firebase_token = await getToken();
            // const data = { email: values.email, token: res.token, firebase_token: firebase_token };
            const data = { email: values.email, token: res.token };
            const resOfWithoutLoginApi = await setUserWithoutOTPVerification(data);
            props.authActions.login(resOfWithoutLoginApi.jwtToken);
            props.authActions.setUser(resOfWithoutLoginApi);
            props.history.push({
              pathname: '/'
            });
          }

          //props.login(accessToken);
        })
        .finally(() => {
          disableLoading();
          setSubmitting(false);
        });
    }
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.EMAIL'
            })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PASSWORD'
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              {' '}
              <FormattedMessage id="AUTH.LOGIN.SIGNIN" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch),
    authActions: bindActionCreators(auth.actions, dispatch)
  };
};

export default connect(null, mapDispatchToEvents)(injectIntl(Login));
