import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';

const initialStateValue = {
  date: '',
  startTime: '',
  endTime: ''
};
const UpdateTimeAppointmentForm = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialStateValue);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    startTime: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    endTime: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });

  return (
    <div className="form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(value) => props.submitFunction(value)}
      >
        {() => (
          <Form encType="multipart/form-data" autoComplete="off">
            <div className="row">
              <div className="col-lg-6">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.DATE" />
                </label>
                <Field
                  placeholder="Date"
                  type="date"
                  className={`form-control form-control-solid py-5 px-6 `}
                  name="date"
                />
                <div>
                  <ErrorMessage name="date">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-3">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.START_TIME" />
                </label>
                <Field
                  placeholder="Start Time"
                  type="time"
                  className={`form-control form-control-solid py-5 px-6`}
                  name="startTime"
                />
                <div>
                  <ErrorMessage name="startTime">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="col-lg-3">
                <label className="col-form-label">
                  <FormattedMessage id="DASHBOARD.END_TIME" />
                </label>
                <Field
                  placeholder="End Time"
                  type="time"
                  className={`form-control form-control-solid py-5 px-6 `}
                  name="endTime"
                />
                <div>
                  <ErrorMessage name="endTime">
                    {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <br />
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
              <button type="submit" className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                <span>
                  {' '}
                  <FormattedMessage id="UPDATE.TIME" />
                </span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(UpdateTimeAppointmentForm);
