import React, { useEffect, useState, useContext } from 'react';
import QuickUserToggler from '../extras/QuiclUserToggler';
import UserNotificationsDropdown from '../extras/dropdowns/UserNotificationsDropdown';
import { isAdmin, isInterpreter, isUser } from '../../../../app/servics/CommonFunctions';
import ChatNotificationTopBar from '../../../../app/pages/ChatMessage/chatTopBar';

export function Topbar() {
  return (
    <div className="topbar">
      {isAdmin() || isInterpreter() ? <UserNotificationsDropdown /> : null}
      {isUser() || isInterpreter() ? <ChatNotificationTopBar /> : null}
      {<QuickUserToggler />}
    </div>
  );
}
