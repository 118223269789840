import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SET_PEER_ID: 'SET_PEER_ID'
};

const initialAuthState = {
  peer_id: null
};

export const reducer = persistReducer(
  { storage, key: 'ens-peer', whitelist: ['peer_id'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SET_PEER_ID: {
        return { ...state, peer_id: action.payload.value };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setPeerId: (value) => ({ type: actionTypes.SET_PEER_ID, payload: { value } })
};
