import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import { requestPassword } from '../_redux/authCrud';
import * as common from '../../../../redux/commonReducer';

const initialValues = {
  email: ''
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.WRONGEMAIL'
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '3' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /* if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      requestPassword(values.email)
        .then((res) => {
          setIsRequested(true);
          props.commonActions.showSnackBar(true, res.message);
          props.history.push('/auth/login');
        })
        .finally(() => {
          setIsRequested(false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              {' '}
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.DESC" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({
                  id: 'AUTH.REGISTER.EMAIL'
                })}
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'email'
                )}`}
                name="email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-success font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="SUBMIT" />
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-danger font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="CANCEL" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default injectIntl(connect(null, mapDispatchToEvents)(ForgotPassword));
