import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { bindActionCreators } from 'redux';
import * as common from '../../../../redux/commonReducer';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PositionedSnackbar(props) {
  // console.log(props);
  const handleClose = (event, reason) => {
    props.commonActions.closeSnackBar();
  };

  return (
    <React.Fragment>
      <Snackbar
        open={props.common.snackbar.show}
        style={{ height: '100%' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        // autoHideDuration={props.common.snackbar.visibleTime}
        // autoHideDuration={false}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={props.common.snackbar.type}>
          {props.common.snackbar.message}
        </Alert>
      </Snackbar>

      {props.children}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common
  };
};

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(PositionedSnackbar);
