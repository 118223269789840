// import { NULL } from 'node-sass';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SET_SETTING_INFO: 'SET_SETTING_INFO'
};

const initialAuthState = null;

export const reducer = persistReducer(
  { storage, key: 'ens-setting' },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SET_SETTING_INFO: {
        return { ...state, ...action.payload };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setSettingData: (value) => ({ type: actionTypes.SET_SETTING_INFO, payload: { value } })
};
