import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setPassword } from '../_redux/authCrud';
import { bindActionCreators } from 'redux';
import * as common from '../../../../redux/commonReducer';
import * as queryString from 'query-string';
import * as auth from '../_redux/authRedux';

const initialValues = {
  password: '',
  confirmPassword: ''
};

function SetPassword(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [uId, setUId] = useState('');

  useEffect(() => {
    if (props.location.search) {
      const params = new URLSearchParams(props.location.search);
      if (params.get('u')) {
        setUId(atob(params.get('u')));
      } else {
        props.history.push('/auth/login');
      }
    }
  }, []);

  const RegistrationSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: 'MINIMUM'
        }) +
          '6' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .max(
        50,
        intl.formatMessage({
          id: 'MAXIMUM'
        }) +
          '50' +
          intl.formatMessage({
            id: 'SYMBOLS'
          })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({
            id: 'AUTH.VALIDATION.PASSWORD_NOT_MATCH'
          })
        )
      })
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    /*if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }*/

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const { token } = queryString.parse(window.location.search);
      values.token = token;
      setSubmitting(true);
      enableLoading();
      setPassword({ ...values, id: uId })
        .then((res) => {
          disableLoading();
          props.authActions.login(res.jwtToken);
          props.authActions.setUser(res);
          props.history.push({
            pathname: '/'
          });
        })
        .finally(() => {
          setSubmitting(false);
          disableLoading();
        });
    }
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.NEWPASSWORD.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.NEWPASSWORD.DESC" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.PASSWORD'
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: 'AUTH.REGISTER.CPASSWORD'
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'confirmPassword'
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps('confirmPassword')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              <FormattedMessage id="SUBMIT" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="CANCEL" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToEvents = (dispatch) => {
  return {
    commonActions: bindActionCreators(common.actions, dispatch),
    authActions: bindActionCreators(auth.actions, dispatch)
  };
};

export default injectIntl(connect(null, mapDispatchToEvents)(SetPassword));
