import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

function DeletePopup(props) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{props.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <button type="button" onClick={props.handleCancel} className={props.cacelColor}>
          {props.cancelText}
        </button>

        <button type="button" onClick={props.handleOk} className={props.okColor}>
          {props.okText}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePopup;
